import React from "react";
import ReactTable from "react-table";
import axios from "axios";
import {setRolesList} from 'actions/index'
import InputMask from 'react-input-mask';

import permissionsList from "../../../services/Authorization/permissions.json";
import { withTranslation } from 'react-i18next';

// reactstrap components
import {
    Button,
    Row,
    Col,
    Modal,
    CardBody,
    Card,
    Input, Label, FormGroup
} from "reactstrap";
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';

function mapDispatchToProps(dispatch) {
    return {
        fetchRoles: () => dispatch(setRolesList())
    }
}

function mapStateToProps(state) {
    return {
        rolesList: state.allOtherReducers.rolesList,
    };
}

class RolesTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            editContent: false,
            openPermissionsModal: false,
            userId: '',
            editingRow: -1,
            isDirty: false,
            rowData: "",
            permissionsList: permissionsList,
            selectedPermissions: []
        }
        this.renderEditable = this.renderEditable.bind(this);

        this.toggleEditable = this.toggleEditable.bind(this);
        this.renderActiveButtons = this.renderActiveButtons.bind(this);
        this.deactivateUser = this.deactivateUser.bind(this);
        this.activateUser = this.activateUser.bind(this);
    }


    handleInputChange = (cellInfo, event) => {
        let data = [...this.props.rolesList];
        data[cellInfo.index][cellInfo.column.id] = event.target.value;

        this.setState({data});
    };

    renderEditable(cellInfo) {

        const cellValue = this.props.rolesList[cellInfo.index][cellInfo.column.id];

        const {editingRow} = this.state;
        return (
            <div>
                {editingRow === cellInfo.index ? (
                    <Input
                        maskChar=""
                        mask=""
                        placeholder=""
                        onChange={this.handleInputChange.bind(null, cellInfo)}
                        value={cellValue}
                        tag={InputMask}
                    />
                ) : (<div>
                    {cellInfo.value}
                </div>)}
            </div>
        );
    }
    deactivateUser(cellInfo) {
        const url = `${process.env.REACT_APP_API_URL}/api/roles/${cellInfo.original._id}`;

        axios.put(url, {
            status: 'inactive'
        }, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.props.fetchRoles();
            return res.data
        })
    }

    activateUser(cellInfo) {
        const url = `${process.env.REACT_APP_API_URL}/api/roles/${cellInfo.original._id}`;

        axios.put(url, {
            status: 'active'
        }, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.props.fetchRoles();
            return res.data
        })
    }

    toggleEditable(index) {

        const {isDirty} = this.state;
        if (!isDirty) {
            this.setState({
                editingRow: index,
                isDirty: true
            });
        } else {
            this.setState({
                editingRow: -1,
                isDirty: false
            })
        }
    }

    handleCheckedPermissions(e, permission) {
        if (e.target.checked) {
            this.state.selectedPermissions.push(permission);
        } else {
            this.state.selectedPermissions = this.state.selectedPermissions.filter(p => p !== permission);
        }

        this.setState({
            selectedPermissions: Array.from(new Set(this.state.selectedPermissions))
        })
    }

    showRolesForm(role) {
        this.props.setEditingRole(role);
    }


    renderActiveButtons(row) {
        if (row.original.status === 'active') {
            let statusAction ='';


            return (<div>
                <Button
                    onClick={() => this.showRolesForm(row.original)}
                    size="xs"
                    className="btn-icon btn-neutral btn btn-info"
                ><i className="fa fa-edit"
                /></Button>

                {!(['superuser', 'employee'].includes(row.original.name)) && <Button
                    onClick={() => this.deactivateUser(row)}
                    size="xs"
                    className="btn-icon btn-neutral btn btn-info"
                ><i className="fa fa-power-off"
                /></Button>}
            </div>)
        } else {

            return (!(['superuser', 'employee'].includes(row.original.name)) && <div>
                <Button
                    onClick={() => this.activateUser(row)}
                    size="xs"
                >{this.props.t('Activate')}</Button>
            </div>)

        }
    }

    renderPermissions(role) {
        return <span>{role.permissions.length}</span>
    }

    render() {
        return (
            <>
                <div className="content">
                    <ReactTable
                        data={this.props.rolesList}
                        columns={[
                            {
                                Header: this.props.t("Role Id"),
                                accessor: "_id",
                                show: false
                            },
                            // {
                            //     Header: "Name",
                            //     accessor: "name",
                            // },
                            {
                                Header: this.props.t("Label"),
                                accessor: "label",
                                Cell: this.renderEditable
                            },
                            {
                                Header: this.props.t("Permissions Count"),
                                accessor: "permissions",
                                Cell: row => this.renderPermissions(row.original)
                            },
                            {
                                Header: this.props.t("Status"),
                                accessor: "status",
                            },
                            {
                                Header: this.props.t("Actions"),
                                id: "activateToggle",
                                width: 150,
                                Cell: ((value) => (this.renderActiveButtons(value)))
                            }

                        ]}
                        defaultPageSize={1}
                        pageSize={this.props.rolesList.length}
                        showPaginationTop={false}
                        showPaginationBottom={false}
                        className="-striped -highlight primary-pagination"
                    />
                </div>
            </>
        );
    }
}


export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(RolesTable)));
