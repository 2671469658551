import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import classnames from 'classnames';
import {connect} from 'react-redux';
import {
    Row,
    Col, Input, Label, FormGroup, Button, CardBody
} from "reactstrap";
import axios from "axios";
import {setRolesList} from "../../../actions";
import permissionsList from "../../../services/Authorization/permissions.json";
import Select from "react-select";
import {withTranslation} from 'react-i18next';

class RegisterRoleForm extends Component {
    constructor() {
        super();
        this.state = {
            name: '',
            label: '',
            status: 'active',
            errors: '',
            permissions: [],
            permissionsList: permissionsList,
            canManageRoles: [],
            nonEditableRoles: ['employee', 'superuser']
        };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        const role = this.props.updateRole;

        if (role) {
            this.setState({
                name: role.name,
                label: role.label,
                permissions: role.permissions,
                canManageRoles: role.canManageRoles
            })
        }

    }


    handleCheckedRoles(e, role) {
        if (e.target.checked) {
            this.state.canManageRoles.push(role._id);
        } else {
            this.state.canManageRoles = this.state.canManageRoles.filter(r => r !== role._id);
        }

        this.setState({
            canManageRoles: Array.from(new Set(this.state.canManageRoles))
        })
    }

    onChange(e) {
        let value, name;

        if (e.target) {
            value = e.target.value;
            name = e.target.name;
        } else {
            value = e.value;
            name = 'status';
        }

        this.setState({[name]: value});
    }

    slug(str) {
        return String(str)
            .normalize('NFKD')
            .replace(/[\u0300-\u036f]/g, '')
            .trim()
            .toLowerCase()
            .replace(/[^a-z0-9 -]/g, '')
            .replace(/\s+/g, '-')
            .replace(/-+/g, '-');
    }

    onSubmit(e) {
        e.preventDefault();

        let newRole = {
            label: this.state.label,
            status: this.state.status,
            canManageRoles: this.state.canManageRoles
        };

        if (this.props.canUpdatePermissions) {
            newRole['permissions'] = this.state.permissions
        }

        let url = `${process.env.REACT_APP_API_URL}/api/roles`,
            method = 'post';

        if (this.props.updateRole) {
            url += `/${this.props.updateRole._id}`;
            method = 'put';

            if (this.state.nonEditableRoles.includes(newRole['name'])) {
                delete newRole['status']
            }
        } else {
            newRole['name'] = this.slug(this.state.label)
        }


        axios[method](url, newRole, {
            headers: {
                'Authorization': localStorage.jwtToken
            }
        }).then(res => {
            this.props.fetchRoles();
            this.setState({
                name: '',
                label: '',
                status: 'active',
                errors: '',
                permissions: [],
                canManageRoles: []
            })
            this.props.toggleRolesForm();
        }).catch(err => {
            this.setState({
                errors: err.response.data
            })
        })


    }

    handleCheckedPermissions(e, permission) {
        if (e.target.checked) {
            this.state.permissions.push(permission);
        } else {
            this.state.permissions = this.state.permissions.filter(r => r !== permission);
        }

        this.setState({
            permissions: Array.from(new Set(this.state.permissions))
        })
    }

    render() {
        const {errors} = this.state;

        return (
            <Row>
                <Col>
                    <CardBody>
                        <Button color="info" onClick={() => this.props.toggleRolesForm()}>
                            {this.props.t('Cancel')}
                        </Button>
                        <form noValidate onSubmit={this.onSubmit}>
                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <input
                                            type="hidden"
                                            className={classnames('form-control form-control-lg', {
                                                'is-invalid': errors.name
                                            })}

                                            readOnly={!!this.props.updateRole && this.state.nonEditableRoles.includes(this.state.name)}
                                            placeholder={this.props.t("Name")}
                                            name="name"
                                            value={this.state.name}
                                            onChange={this.onChange}
                                        />
                                        {errors.name && (
                                            <div className="invalid-feedback">{errors.name}</div>
                                        )}
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className={classnames('form-control form-control-lg', {
                                                'is-invalid': errors.label
                                            })}
                                            placeholder={this.props.t("Label")}
                                            name="label"
                                            value={this.state.label}
                                            onChange={this.onChange}
                                        />
                                        {errors.label && (
                                            <div className="invalid-feedback">{errors.label}</div>
                                        )}
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <div className="form-group">
                                        <div>
                                            <Select options={[
                                                {value: 'active', label: this.props.t('Active')},
                                                {value: 'inactive', label: this.props.t('Inactive')}
                                            ]}
                                                    isDisabled={!!this.props.updateRole && this.state.nonEditableRoles.includes(this.state.name)}
                                                    name="status"
                                                    onChange={this.onChange}
                                                    defaultValue={{value: 'active', label: this.props.t('Active')}}/>
                                        </div>
                                        {errors.status && (
                                            <div className="invalid-feedback">{errors.status}</div>
                                        )}

                                    </div>

                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <h4 className="m-0">{this.props.t('Can manage roles')}</h4>
                                    <div style={{
                                        margin: '10px 0',
                                        height: "160px",
                                        overflowY: "scroll",
                                        overflowX: 'hidden',
                                        'border': 'solid 1px gray',
                                        'padding': '10px'
                                    }}>
                                        {this.props.rolesList.map((r, index) => <FormGroup className=""
                                                                                           key={`r_${index}`}
                                                                                           style={{"fontSize": "14px"}}
                                        >
                                            <Input
                                                checked={this.state.canManageRoles.includes(r._id)}
                                                id={r.name}
                                                addon type="checkbox"
                                                onChange={(e) => this.handleCheckedRoles(e, r)}
                                            />
                                            <Label check for={r.name} className="ml-1">
                                                {r.label}
                                            </Label>
                                        </FormGroup>)}
                                        {errors.canManageRoles && (
                                            <div className="invalid-feedback d-block">{errors.canManageRoles}</div>
                                        )}
                                    </div>
                                </Col>
                            </Row>

                            {
                                this.props.canUpdatePermissions &&
                                <Row>
                                    <Col>
                                        <h4 className="m-0 d-inline mr-1">{this.props.t('Permissions')}</h4>

                                        {(this.state.permissions.length !== this.state.permissionsList.length) && <a
                                            onClick={e => {
                                                this.setState({
                                                    permissions: this.state.permissionsList.map(p => p.id)
                                                })
                                            }}
                                            className={'btn btn-sm btn-outline-success'} style={{'fontSize': '10px'}}>
                                            <i className={'fa fa-check text-dark hover:text-white'}></i> {this.props.t('Check all')}
                                        </a>}

                                        {(this.state.permissions.length === this.state.permissionsList.length) && <a
                                            onClick={e => {
                                                this.setState({
                                                    permissions: []
                                                })
                                            }}
                                            className={'btn btn-sm btn-outline-warning'} style={{'fontSize': '10px'}}>
                                            <i className={'fa fa-times text-dark'}></i> {this.props.t('Un Check all')}
                                        </a>}
                                        <div style={{
                                            margin: '10px 0',
                                            height: "290px",
                                            overflowY: "scroll",
                                            overflowX: 'hidden',
                                            'border': 'solid 1px gray',
                                            'padding': '10px'
                                        }}>
                                            {
                                                this.state.permissionsList.map((p, index) => <Row
                                                    style={{'marginBottom': '-18px'}} key={"p_row" + index}>
                                                    <Col
                                                        md={12}>
                                                        <FormGroup className="d-inline mr-2"
                                                                   style={{"fontSize": "16px"}}>
                                                            <Input
                                                                checked={this.state.permissions.includes(p.id)}
                                                                id={"p_" + index}
                                                                addon type="checkbox"
                                                                onChange={(e) => this.handleCheckedPermissions(e, p.id)}
                                                            />
                                                            <Label check for={"p_" + index} className="ml-1">
                                                                {p.id}
                                                            </Label>

                                                            <ul className={'mb-0'}>
                                                                {p.descriptions?.map((d, index) =>
                                                                    <li style={{'fontSize': '13px'}}
                                                                        dangerouslySetInnerHTML={{__html: d}}
                                                                        key={`desc_${index}`}>
                                                                    </li>
                                                                )}
                                                            </ul>
                                                        </FormGroup>
                                                    </Col></Row>)
                                            }
                                        </div>
                                        {errors.permissions && (
                                            <div className="invalid-feedback d-block">{errors.permissions}</div>
                                        )}
                                    </Col>
                                </Row>
                            }

                            <Row>
                                <Col md={6}>
                                    <Button color="info" type="submit" className="w-100">
                                        {this.props.t('Submit')}
                                    </Button>
                                </Col>
                            </Row>
                        </form>
                    </CardBody>
                </Col>
            </Row>
        );
    }
}

const mapStateToProps = state => ({
    rolesList: state.allOtherReducers.rolesList.filter(r => r.status === 'active')
});

function mapDispatchToProps(dispatch) {
    return {
        fetchRoles: () => dispatch(setRolesList())
    }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(RegisterRoleForm)));
